import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosInstance } from 'axios';

import { API_USERS_BASE_URL } from '@/config';
import { SavedSearch } from '@/types';

export function getSavedSearches(axios: AxiosInstance, isEnabled: boolean) {
  return useQuery({
    queryKey: ['saved-searches'],
    queryFn: async () => {
      const response = await axios.get<SavedSearch[]>(`${API_USERS_BASE_URL}/saved-searches`);

      return response.data;
    },
    enabled: isEnabled,
  });
}

export function getSavedSearchById(axios: AxiosInstance, id: string) {
  return useQuery({
    queryKey: ['saved-search'],
    queryFn: async () => {
      const response = await axios.get<SavedSearch>(`${API_USERS_BASE_URL}/saved-searches/${id}`);

      return response.data;
    },
  });
}

type AddSavedSearchVariables = {
  name: string;
  url: string;
  savedSearchCriteria: string;
  enableEmailAlerts: boolean;
};

export function addSavedSearch(axios: AxiosInstance) {
  return useMutation({
    mutationFn: async ({
      name,
      url,
      savedSearchCriteria,
      enableEmailAlerts,
    }: AddSavedSearchVariables) => {
      const response = await axios.post<SavedSearch | null>(
        `${API_USERS_BASE_URL}/saved-searches`,
        {
          name,
          url,
          savedSearchCriteria,
          enableEmailAlerts,
        },
      );

      return response.data;
    },
  });
}

type UpdateSavedSearchVariables = {
  savedSearchId: string;
  name: string;
  url: string;
  enableEmailAlerts: boolean;
  savedSearchCriteria: string;
};

export function updateSavedSearch(axios: AxiosInstance) {
  return useMutation({
    mutationFn: async ({
      savedSearchId,
      name,
      url,
      enableEmailAlerts,
      savedSearchCriteria,
    }: UpdateSavedSearchVariables) => {
      const response = await axios.put<SavedSearch | null>(
        `${API_USERS_BASE_URL}/saved-searches/${savedSearchId}`,
        {
          name,
          url,
          enableEmailAlerts,
          savedSearchCriteria,
        },
      );

      return response.data;
    },
  });
}

type RemoveSavedSearchVariables = {
  savedSearchId: string;
};

export function removeSavedSearch(axios: AxiosInstance) {
  return useMutation({
    mutationFn: async ({ savedSearchId }: RemoveSavedSearchVariables) => {
      const response = await axios.delete<boolean>(
        `${API_USERS_BASE_URL}/saved-searches/${savedSearchId}`,
      );

      return response.data;
    },
  });
}

type ShareSavedSearchViaEmailVariables = {
  recipient: string;
  savedSearchId: string;
};

export function shareSavedSearchViaEmail(axios: AxiosInstance) {
  return useMutation({
    mutationFn: async ({ recipient, savedSearchId }: ShareSavedSearchViaEmailVariables) => {
      const response = await axios.post<boolean>(
        `${API_USERS_BASE_URL}/saved-searches/${savedSearchId}/emails`,
        {
          recipient,
        },
      );

      return response.data;
    },
  });
}
