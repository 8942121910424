import cn from 'classnames';
import React from 'react';

import { Icon, IconName } from '@components/Icon';
import './Modal.css';
import { ModalProps } from './Modal.types';

const Modal: React.FC<ModalProps> = ({
  onTop,
  children,
  classNames,
  maxHeightClass,
  customCloseBtn,
  haveClose,
  onClose,
  show,
  theme,
  isSmallScreen,
  customAllMediaButton,
}) => {
  return (
    <>
      {show && isSmallScreen && (
        <>
          <div className="primary-modal-main">
            {customAllMediaButton}
            <div className="swiper-custom-pagination" />
            {customCloseBtn}
            <div className="primary-modal-sub media-modal">
              <div className={cn(['primary-modal-box', classNames])}>
                <div className="h-full">{children}</div>
              </div>
            </div>
          </div>
          <div className={'primary-modal-bg'}></div>
        </>
      )}
      {show && !isSmallScreen && (
        <>
          <div className="primary-modal-main">
            <div
              className={cn([
                'primary-modal-sub',
                maxHeightClass ? `${maxHeightClass} sm:!overflow-y-auto` : '',
              ])}>
              <div className={cn(['primary-modal-box', classNames])}>
                <div className="h-full">
                  {customAllMediaButton && (
                    <div
                      role="none"
                      className="absolute bottom-4 z-10 rounded-sm px-5 py-3 bg-white-100 border-slate-300 !h-[20px] !w-[60px] top-[-2.625rem]">
                      {customAllMediaButton}
                    </div>
                  )}
                  {customCloseBtn ||
                    (haveClose && (
                      <button
                        className={`primary-modal-close-btn ${theme}`}
                        onClick={onClose}
                        data-testid="modal-close">
                        <Icon name={IconName.CLOSE} />
                      </button>
                    ))}
                  {children}
                </div>
              </div>
            </div>
          </div>
          <div className={onTop ? 'primary-modal-bg on-top' : 'primary-modal-bg'}></div>
        </>
      )}
    </>
  );
};

export default Modal;
