import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosInstance } from 'axios';

import { API_USERS_BASE_URL } from '@/config';
import {
  DetailedFavoriteCollection,
  FavoriteCollection,
  FavoriteCollectionFavorite,
  SummaryFavoriteCollection,
  Favorite,
  PaginatedFavoriteParams,
} from '@/types';

export function getFavoriteCollections(axios: AxiosInstance, isEnabled: boolean) {
  return useQuery({
    queryKey: ['favorite-collections'],
    queryFn: async () => {
      const response = await axios.get<SummaryFavoriteCollection[]>(
        `${API_USERS_BASE_URL}/favorite-collections`,
      );
      return response.data;
    },
    enabled: isEnabled,
  });
}

export function getFavoriteCollectionById(axios: AxiosInstance, favoriteCollectionId: string) {
  return useQuery({
    queryKey: ['favorite-collections', favoriteCollectionId],
    queryFn: async () => {
      const response = await axios.get<DetailedFavoriteCollection>(
        `${API_USERS_BASE_URL}/favorite-collections/${favoriteCollectionId}`,
      );
      return response.data;
    },
  });
}

type AddFavoriteCollectionVariables = {
  name: string;
  listingIds: string[];
};

export function addFavoriteCollection(axios: AxiosInstance) {
  return useMutation({
    mutationFn: async ({ name, listingIds }: AddFavoriteCollectionVariables) => {
      const response = await axios.post<FavoriteCollection>(
        `${API_USERS_BASE_URL}/favorite-collections`,
        {
          name,
          listingIds,
        },
      );

      return response.data;
    },
  });
}

type UpdateFavoriteCollectionVariables = {
  favoriteCollectionId: string;
  name: string;
};

export function updateFavoriteCollection(axios: AxiosInstance) {
  return useMutation({
    mutationFn: async ({ favoriteCollectionId, name }: UpdateFavoriteCollectionVariables) => {
      const response = await axios.put<FavoriteCollection>(
        `${API_USERS_BASE_URL}/favorite-collections/${favoriteCollectionId}`,
        {
          name,
        },
      );

      return response.data;
    },
  });
}

type RemoveFavoriteCollectionVariables = {
  favoriteCollectionId: string;
};

export function removeFavoriteCollection(axios: AxiosInstance) {
  return useMutation({
    mutationFn: async ({ favoriteCollectionId }: RemoveFavoriteCollectionVariables) => {
      const response = await axios.delete(
        `${API_USERS_BASE_URL}/favorite-collections/${favoriteCollectionId}`,
      );

      return response.data;
    },
  });
}

type AddFavoritesToFavoriteCollectionVariables = {
  favoriteCollectionId: string;
  favoriteIds: string[];
};

export function addFavoriteToFavoriteCollection(axios: AxiosInstance) {
  return useMutation({
    mutationFn: async ({
      favoriteCollectionId,
      favoriteIds,
    }: AddFavoritesToFavoriteCollectionVariables) => {
      const response = await axios.post<FavoriteCollectionFavorite>(
        `${API_USERS_BASE_URL}/favorite-collections/${favoriteCollectionId}/favorites`,
        {
          favoriteIds,
        },
      );

      return response.data;
    },
  });
}

type RemoveFavoritesFromFavoriteCollectionVariables = {
  favoriteCollectionId: string;
  favoriteIds: string[];
};

export function removeFavoritesFromFavoriteCollection(axios: AxiosInstance) {
  return useMutation({
    mutationFn: async ({
      favoriteCollectionId,
      favoriteIds,
    }: RemoveFavoritesFromFavoriteCollectionVariables) => {
      const response = await axios.delete(
        `${API_USERS_BASE_URL}/favorite-collections/${favoriteCollectionId}/favorites`,
        {
          data: {
            favoriteIds,
          },
        },
      );

      return response.data;
    },
  });
}

type CloneFavoriteCollectionVariables = {
  favoriteCollectionId: string;
};

export function cloneFavoriteCollection(axios: AxiosInstance) {
  return useMutation({
    mutationFn: async ({ favoriteCollectionId }: CloneFavoriteCollectionVariables) => {
      const response = await axios.post<FavoriteCollection>(
        `${API_USERS_BASE_URL}/favorite-collections/${favoriteCollectionId}/clone`,
      );

      return response.data;
    },
  });
}

type ShareFavoriteCollectionViaEmailVariables = {
  recipient: string[];
  favoriteCollectionId: string;
  attachReport: boolean;
};

export function shareFavoriteCollectionViaEmail(axios: AxiosInstance) {
  return useMutation({
    mutationFn: async ({
      recipient,
      favoriteCollectionId,
      attachReport,
    }: ShareFavoriteCollectionViaEmailVariables) => {
      const response = await axios.post<boolean>(
        `${API_USERS_BASE_URL}/favorite-collections/${favoriteCollectionId}/emails`,
        {
          recipient,
          attachReport,
        },
      );

      return response.data;
    },
  });
}

export const getPaginatedCollectionFavorites = async (
  axios: AxiosInstance,
  paginatedFavoriteParams: PaginatedFavoriteParams | null,
) => {
  const response = await axios.post<Favorite[]>(
    `${API_USERS_BASE_URL}/favorite-collections/favoriteCollection-pagination`,
    paginatedFavoriteParams,
  );
  return response;
};

export const getAllCollectionFavorites = async (
  axios: AxiosInstance,
  favoriteCollectionId: string,
  searchQuery: string | null,
) => {
  const response = await axios.get<Favorite[]>(
    `${API_USERS_BASE_URL}/favorite-collections/collection-listingIds/${favoriteCollectionId}?searchQuery=${searchQuery}`,
  );
  return response;
};
