import React, { useMemo } from 'react';
import { Popup, PopupProps } from 'react-map-gl';

import LoadingSpinner from '@components/LoadingSpinner/LoadingSpinner';
import { useApiClient } from '@/hooks/useApiClient';
import { TemplateTypes } from '@/types';
import MapPinPopupContent from './MapPinPopupContent';

import './MapPinPopup.css';

export interface MapPinPopupProps {
  latitude: number;
  listingId: string;
  longitude: number;
  popupProps?: Partial<PopupProps>;
}

export const MapPinPopup: React.FC<MapPinPopupProps> = ({
  latitude,
  listingId,
  longitude,
  popupProps,
}) => {
  const { getListingData } = useApiClient();
  const { data, isLoading } = getListingData(TemplateTypes.ListingPopUp, [listingId]);

  const popupData = useMemo(() => {
    if (!data) {
      return null;
    }

    const listingData = data[0];

    const thumbnailImageUrl = listingData.attributes.find(
      (x) => x.key === 'THUMBNAIL_IMAGE_URL',
    )?.value;
    const name = listingData.attributes.find((x) => x.key === 'NAME')?.value;
    const fullAddress = listingData.attributes.find((x) => x.key === 'FULL_ADDRESS')?.value;
    const size = listingData.attributes.find((x) => x.key === 'SIZE')?.value;

    return {
      thumbnailImageUrl,
      name,
      fullAddress,
      size,
    };
  }, [data]);

  return (
    <Popup
      className="overflow-hidden font-sans rounded-lg map-pin-popup drop-shadow-md shadow-base-black w-[18rem] pb-10"
      closeButton={false}
      latitude={latitude}
      longitude={longitude}
      anchor="top"
      maxWidth="none"
      offset={{
        top: [0, 35],
        left: [35, 25],
        right: [-35, 25],
        'top-left': [25, 25],
        'top-right': [-25, 25],
        'bottom-left': [25, 5],
        'bottom-right': [-25, 5],
      }}
      {...popupProps}>
      {isLoading ? (
        <div className="flex justify-center items-center w-[15.625rem] h-[15.625rem] p-4">
          <LoadingSpinner />
        </div>
      ) : (
        <MapPinPopupContent
          thumbnailUrl={popupData?.thumbnailImageUrl}
          name={popupData?.name}
          fullAddress={popupData?.fullAddress}
          size={popupData?.size}
        />
      )}
    </Popup>
  );
};

export default MapPinPopup;
